.ant-pro-basicLayout {
  min-height: 100vh;
}
.pandectMenu.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  left: 0!important;
}
.pandectMenu .ant-menu-submenu-title {
  position: absolute;
  left: 8px!important;
  .ant-menu-submenu-arrow {
    right: 26px!important;
  }
}
.pandectMenu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.menuBox {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 800px;
  padding: 30px;
  background-color:#001529;
  z-index: 9999;
  overflow-y: auto;
  >div {
      column-count: 3;
      ul {
          break-inside: avoid; 
          box-sizing: border-box;
          padding: 10px;
          p {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 30px;
              color: rgb(235, 230, 230);
              cursor: pointer;
          }
          li {
              height: 28px;
              cursor: pointer;
          }
      }
  }
}