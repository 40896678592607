.loginPage {
  background: rgb(240, 242, 245);
  height: 100vh;
}

.formWrapper {
  padding-top: 100px;
  width: 368px;
  margin: auto;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.errorMessage {
  margin-bottom: 24px;
}

.addonNoPadding {
  :global(.ant-input-group-addon) {
      padding: 0;
  }
}
