// @import '~antd/lib/style/themes/default.less';

// .header {
//   height: 64px;
//   padding: 0 12px 0 0;
//   background: #fff;
//   box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
//   position: relative;
//   display: flex;
//   width: 100%;
// }

// .logo {
//   height: 64px;
//   line-height: 58px;
//   vertical-align: top;
//   display: inline-block;
//   padding: 0 0 0 24px;
//   cursor: pointer;
//   font-size: 20px;
//   img {
//     display: inline-block;
//     vertical-align: middle;
//   }
//   flex-shrink: 0;
// }

// .menu {
//   :global(.anticon) {
//     margin-right: 8px;
//   }
//   :global(.ant-dropdown-menu-item) {
//     width: 160px;
//   }
// }

// i.trigger {
//   font-size: 20px;
//   line-height: 64px;
//   cursor: pointer;
//   transition: all 0.3s, padding 0s;
//   padding: 0 24px;
//   &:hover {
//     background: @primary-1;
//   }
//   flex-shrink: 0;
// }

// .tabs {
//   flex-grow: 1;
//   flex-shrink: 1;
//   min-width: 0;
//   display: flex;
// }

// .tab {
//   display: block;
//   padding: 10px 0;
//   transition: color 0.5s;
//   user-select: none;
//   flex-shrink: 1;
//   min-width: 0;

//   .wrapper {
//     // padding: 0 25px;
//     border-left: #eee solid 1px;
//     width: 100%;
//     display: flex;
//     height: 100%;
//     line-height: 44px;

//     .whiteLeft {
//       width: 25px;
//       min-width: 5px;
//       flex-shrink: 1;
//     }

//     .text {
//       white-space: nowrap;
//       flex-shrink: 1;
//       min-width: 0;
//       overflow: hidden;
//     }

//     .close {
//       flex-shrink: 0;
//       margin-left: 5px;

//       i {
//         border-radius: 50%;
//         padding: 2px;
//         transition: background-color 0.2s;
//         &:hover {
//           background-color: #ddd;
//         }
//       }
//     }

//     .whiteRight {
//       width: 15px;
//       min-width: 5px;
//       flex-shrink: 1;
//     }
//   }

//   &:hover {
//     color: @primary-color;
//   }

//   &.active {
//     border-bottom: @primary-color solid 2px;
//     color: @primary-color;
//   }
// }

.right {
  float: right;
  height: 100%;
  // flex-shrink: 0;
  .action {
    cursor: pointer;
    padding: 0 12px;
    display: inline-block;
    transition: all 0.3s;
    height: 100%;
    > i {
      font-size: 16px;
      vertical-align: middle;
      // color: @text-color;
    }
    // &:hover {
    //   background: @primary-1;
    // }
    // :global(&.ant-popover-open) {
    //   background: @primary-1;
    // }
  }
  // .search {
  //   padding: 0 12px;
  //   &:hover {
  //     background: transparent;
  //   }
  // }
  .account {
    .avatar {
      margin: 20px 8px 20px 0;
      // color: @primary-color;
      // background: rgba(255, 255, 255, 0.85);
      vertical-align: middle;
    }

    .name{
      margin: 5px;
      padding: 0px;
    }
  }
}

.dark {
  height: 64px;
  .action {
    color: rgba(255, 255, 255, 0.85);
    > i {
      color: rgba(255, 255, 255, 0.85);
    }
    &:hover,
    // &:global(.ant-popover-open) {
    //   background: @primary-color;
    // }
    :global(.ant-badge) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

// @media only screen and (max-width: @screen-md) {
//   .header {
//     :global(.ant-divider-vertical) {
//       vertical-align: unset;
//     }
//     .name {
//       display: none;
//     }
//     i.trigger {
//       padding: 0 12px;
//     }
//     .logo {
//       padding-right: 12px;
//       position: relative;
//     }
//     .right {
//       position: absolute;
//       right: 12px;
//       top: 0;
//       background: #fff;
//       .account {
//         .avatar {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }
